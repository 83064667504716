<template>
  <v-expansion-panels accordion flat>
    <v-expansion-panel
      v-for="step in steps"
      :key="`step-${step.step}`"
      :class="`card-bg-step-${step.step}`">
    <v-slide-y-transition appear>
      <base-section
        :step="step.step" 
        :filled-percent="step.filledPercent"
        :title="step.title"
        :sub-title="step.subTitle"
        :content="step.content"
        :without-description="step.withoutDescription"
        :secondButton="step.secondButton"
        :detalle="step.detalle" />
    </v-slide-y-transition>
    <v-expansion-panel-content flat class="ma-0" v-if="!step.withoutDescription">
      <expansion-content
        class="ma-0"
        :step="step.step"
        :with-items="step.items || []"
        :description="step.moreDataDescription"
        :more-data-with-news-button="step.moreDataWithNewsButton" />
    </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'AccordionHome',
  components: {
    BaseSection: () => import('@/views/components/BaseSection.vue'),
    ExpansionContent: () => import('@/views/components/ExpansionContent')
  },
  data() {
    return {
      steps: [
        {
          step: '01',
          filledPercent: '10',
          title: 'DIGITALIZACIÓN',
          content: `
              <p>
                En esta etapa el escribano autenticado mediante su Wallet carga los informes necesarios 
                para realizar la venta -previo estudio de títulos- y los datos para que el vendedor reciba el pago.
              </p>
              <p>Nuestra DApp registra todo en la BNB Chain (Binance Smart Chain)</p>`,
          items: [
            {type: 'description', description: 'Nuestro escribano está realizando:'},
            {type: 'checkbox', checked: true, label: 'Configuración de Wallets'},
            {type: 'checkbox', checked: false, label: 'Estudio de títulos'},
            {type: 'checkbox', checked: false, label: 'Informe dominio'},
            {type: 'checkbox', checked: false, label: 'Informe gravámenes'},
            {type: 'checkbox', checked: false, label: 'Informe inhibición'},
            {type: 'checkbox', checked: false, label: 'Certificado catastral'},
            {type: 'checkbox', checked: false, label: 'Libre deuda Impuesto Inmobiliario'},
            {type: 'checkbox', checked: false, label: 'Libre deuda Tasa General de Inmuebles (TGI)'},
            {type: 'checkbox', checked: false, label: 'Libre deuda Aguas Santafecinas S.A.'},
            {type: 'checkbox', checked: false, label: 'Certificado libre deuda expensas de la unidad, juicios y obligaciones del consorcio'},
            {type: 'checkbox', checked: false, label: 'Certificado de Registro con bloqueo de dominio'},
            {type: 'checkbox', checked: false, label: 'Validación datos vendedor'},
            {type: 'checkbox', checked: false, label: 'Iniciando inscripción'},
          ],
          moreDataDescription: `
              <p>
                Cuando validemos todos los requisitos legales comenzará la etapa de 
                <span class="azul4--text font-weight-bold">INSCRIPCIÓN.</span>
              </p>
              <p class="azul4--text font-weight-bold">
                ¡Vas a poder anotarte para participar en la subasta!
              </p>
              <p>&nbsp;</p>
              <p class="caption chivo">
              *La subasta se realizará como una prueba de concepto (POC - Proof Of Concept), se trata de una experiencia controlada de manera que la aprobación de las wallets que participarán estarán sujetas a derecho de admisión reservado exclusivamente a TOKENFIT.
              </p>`,
          secondButton: 'SABER MÁS',
          detalle: [
            {nombre: 'Duración', valor: 'próximamente'},
            {nombre: 'Fecha Inicio', valor: 'próximamente'},
            {nombre: 'Tiempo a inicio', valor: '-'},
            {nombre: 'Actores', valor: 'ESCRIBANO + VENDEDOR'},
          ]
        },
        {
          step: '02',
          filledPercent: '0',
          title: 'INSCRIPCIÓN',
          content: `
              <p>Los interesados en participar de la subasta realizan un OnBoarding de sus datos en nuestra DApp y un KyC inicial.</p> 
              <p>Las wallets validadas en esta instancia por el escribano serán las que podrán acceder a la subasta.</p>`,
          items: [
            {type: 'description', description: 'Los interesados y nuestro escribano están realizando:'},
            {type: 'checkbox', checked: true, label: 'OnBoarding de participantes en la subasta'},
            {type: 'checkbox', checked: false, label: 'Aceptación de Términos y Condiciones'},
            {type: 'checkbox', checked: false, label: 'Validaciones de datos y procesos'},
            {type: 'checkbox', checked: false, label: 'Iniciando la subasta'},
          ],
          moreDataDescription: `
              <p>Cuando validemos todos los requisitos legales comenzará la subasta.</p>
              <p>
                <span class="azul4--text font-weight-bold">Observa la subaasta en vivo:</span>
                Si no te inscribiste, también vas a poder observar la subasta en plena acción. 
              </p>
              <p>
                <span class="azul4--text font-weight-bold">Inscribite a nuestras NEWS</span>
                y te avisaremos antes que inicie. ¡No te la pierdas!
              </p>`,
          moreDataWithNewsButton: true,
          secondButton: 'INSCRIBITE',
          detalle: [
            {nombre: 'Duración', valor: 'próximamente'},
            {nombre: 'Fecha Inicio', valor: 'próximamente'},
            {nombre: 'Tiempo a inicio', valor: '-'},
            {nombre: 'Actores', valor: 'INTERESADOS + ESCRIBANO'},
          ]
        },
        {
          step: '03',
          filledPercent: '0',
          title: 'SUBASTA',
          content: `
              <p>Las wallets registradas durante la Inscripción van a poder participar del proceso de subasta. Podrán ofertar mediante la TRF de una seña en USDT (10% de la oferta) utilizando nuestra DApp.</p>
              <p>Nuestros Smart Contracts resguardarán la mejor oferta, devolviendo las inferiores automáticamente a los oferentes. 
                 Finalizada la Subasta, el mayor oferente, una vez que haya completado el saldo total de la oferta, recibirá un NFT que le dará acceso a la etapa de Escrituración.</p>
              <p class="font-weight-bold">También vas a poder observar OnLine la subasta en el momento en que ocurra.</p>`,
          moreDataDescription: `
              <p>La página para realizar ofertas estará disponible el día de la subasta y quienes estén inscriptos podrán hacerlo conectando sus Wallets Metamask.</p>
              <p>La oferta mayor será resguardada por nuestros Smart Contracts y las inferiores se devolverán de manera automática. 
                Al finalizar el período de subasta la wallet ganadora recibirá un NFT que le dará acceso a las siguientes etapas de la venta.</p>
              <p>Quienes deseen ser espectadores de la subasta podrán ingresar y visualizarán todo el proceso OnLine.</p>
              <p>Una vez finalizada la subasta, el ganador deberá transferir en USDT el saldo total de la oferta y los gastos de escrituración, los cuales quedarán 
                resguardados por nuestros Smart Contracts hasta la finalización de la etapa siguiente</p>
              <p>
                <span class="azul4--text font-weight-bold">Inscribite a nuestras NEWS</span>
                y te avisaremos antes que inicie. ¡No te la pierdas!
              </p>`,
          moreDataWithNewsButton: true,
          secondButton: 'INSCRIBITE',
          detalle: [
            {nombre: 'Duración', valor: 'próximamente'},
            {nombre: 'Fecha Inicio', valor: 'próximamente'},
            {nombre: 'Tiempo a inicio', valor: '-'},
            {nombre: 'Actores', valor: 'REGISTRADOS'},
          ]
        } ,
        {
          step: '04',
          filledPercent: '0',
          title: 'ESCRITURACIÓN',
          content: `
              <p><b>En esta etapa el ganador de la Subasta debe completar toda la información necesaria para dar cumplimiento a los requisitos legales de la venta.</b></p>
              <p>Una vez cumplidos los requisitos previos para la realización de la transferencia dominial del inmueble, el escribano autorizará la venta y los fondos 
                se transferirán automáticamente al vendedor y al escribano.</p>
              <p class="font-weight-bold">El adquirente en subasta recibirá un NFT coleccionable con toda la información del proceso y acceso al material exclusivo.</p>`,
          withoutDescription: true,
          secondButton: 'INSCRIBITE A NUESTRAS NEWS',
          detalle: [
            {nombre: 'Duración', valor: 'próximamente'},
            {nombre: 'Fecha Inicio', valor: 'próximamente'},
            {nombre: 'Tiempo a inicio', valor: '-'},
            {nombre: 'Actores', valor: 'GANADOR + ESCRIBANO'},
          ]
        }        
      ]
    }
  }

  

}
</script>

<style>

</style>